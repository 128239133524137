import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  isAdvertiser,
  isPublisher,
  requestRetailMediaAccountID
} from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import useQueryParams from '~/hooks/useQuery'

import {
  getAdTypesService,
  getAdTypesServiceKey
} from '../services/configuration/ad-types'

export const useAdTypes = (
  { useRMID = true }: { useRMID?: boolean } = { useRMID: true }
) => {
  const { isWhiteLabel } = useAppInfo()
  // Por enquanto só pega do query params. Se preciso, podemos receber alternativa por props do useAdTypes
  const linkId = useQueryParams().get('rmid')
  const requestKey = requestRetailMediaAccountID

  const queryKey = [getAdTypesServiceKey, requestKey, linkId]

  const cachedData = useGetFetchQuery(queryKey) as CampaignAdType[]

  const enabled = useMemo(
    () =>
      isWhiteLabel ||
      (!isWhiteLabel && isAdvertiser() && !!linkId) ||
      (!isWhiteLabel && isPublisher()),
    [isWhiteLabel, linkId]
  )

  const {
    isPending,
    data: response = [],
    error
  } = useQuery({
    enabled,
    queryKey,
    queryFn: async () => {
      const response = await getAdTypesService({
        [requestKey]: useRMID ? linkId : null
      })
      // return response?.data?.ad_types
      return [...(response?.data?.ad_types ?? [])]
    }
  })

  const data = cachedData || response

  return { isPending: isPending && enabled, data, error, queryKey }
}
